import * as React from 'react';
import glamorous from 'glamorous';
import { Badge, IconButton, Tooltip, Typography } from '@material-ui/core';
import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// tslint:disable-next-line:no-any
const CardWrapper = glamorous.div<{ theme?: any }>(props => ({
    position: 'relative',
    padding: 15,
    //   border: '1px solid',
    display: 'flex',
    flexFlow: 'column',
    textAlign: 'center',
    fontSize: '18px',
    height: '100%',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 14px 0 rgba(81,115,255,0.09)',
    width: '100%',
    cursor: 'pointer',
    '& .title': {
        fontSize: '18px',
        color: props.theme.TemplateColor.Primary,
    },
    '& .description': {
        fontSize: '14px',
        maxWidth: '220px',
        margin: '0 auto',
    },
    '& .icon': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '60',
        padding: '20px 0',
        '& svg': {
            height: 'auto',
            width: '60px',
            color: props.theme.TemplateColor.Primary
        }
    }
}));

// tslint:disable-next-line:no-any
const CounterWrapper = glamorous.div<{ theme?: any }>((props) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly',
    paddingBottom: '15px',
    '& .badge-label': {
        paddingTop: 10,
    },
    '& .badge-label:hover': {
        textDecoration: 'underline'
    }
}));

// tslint:disable-next-line:no-any
const BadgeContainer = glamorous.div<{ theme?: any }>((props) => ({
    
}));

interface Item {
    name: string;
    onClick?: () => void;
    count: number;
    maxCount?: number;
}

interface CommonMaterialCardProps {
    icon: React.ReactNode;
    title: string;
    description: string;
    onClick?: () => void;
    items?: Item[];
    onLearnMore?: () => void;
    onDocument?: () => void;
}

interface CommonMaterialCardState {
    // 
}

export default class CommonMaterialCard extends React.Component<CommonMaterialCardProps, CommonMaterialCardState>  {

    public componentDidMount() {
        // 
    }

    public componentWillUnmount() {
        // 
    }

    public render() {
        return (
            <React.Fragment>
                <CardWrapper>
                    <div
                        className="title"
                        onClick={this.props.onClick}
                    >
                        {this.props.title}
                    </div>
                    <div className="icon" onClick={this.props.onClick}>
                        {this.props.icon}
                    </div>
                    {
                        this.props.items?.length && (
                            <CounterWrapper>
                                {
                                    this.props.items.map((item, index) => {
                                        return (
                                            <BadgeContainer key={index}>
                                                <Badge
                                                    color="primary"
                                                    badgeContent={item.count}
                                                    showZero={true}
                                                    max={item.maxCount ? item.maxCount : 100}
                                                >
                                                    <Typography
                                                        className="badge-label"
                                                        onClick={item.onClick}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                </Badge>
                                            </BadgeContainer>
                                        );
                                    })
                                }
                            </CounterWrapper>
                        )
                    }
                    <div className="description">{this.props.description}</div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            gap: '5px'
                        }}
                    >
                        {!!this.props.onLearnMore && (
                            <Tooltip title="Learn More">
                                <IconButton
                                    onClick={this.props.onLearnMore}
                                    size="small"
                                >
                                    <VideoLibraryOutlinedIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {!!this.props.onDocument && (
                            <Tooltip title="More Information">
                                <IconButton
                                    onClick={this.props.onDocument}
                                    size="small"
                                >
                                    <ErrorOutlineIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </CardWrapper>
            </React.Fragment>
        );
    }
}
