import * as React from 'react';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';
import { graphql } from 'react-apollo';
import { browserHistory } from '../components/AppContent';
import { linkToPage } from '../App';
import CommonMaterialCard from './CommonMaterialCard';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import glamorous from 'glamorous';
import { RvLoader } from '../components/Loader';

// tslint:disable-next-line:no-any
const CardWrapper = glamorous.div<{ theme?: any }>(props => ({
    padding: 15,
    '& .custom-grid': {

    },
}));

enum RoleCategory {
    internal = '0',
    external = '1',
    all = ''
}

interface QueryResult {
    loading?: boolean;
    networkStatus?: number;
    error?: ApolloError;
    // tslint:disable-next-line:no-any
    administrator: {
        adminStatistics: {
            roleCounts: {
                external: number;
                internal: number;
                total: number;
            }
        }
    };

}

interface CardStatsProps {
    className?: string;
    color?: 'orange' | 'blue' | 'red' | 'green';
    linkTo?: string;
    id?: string;
    data: QueryResult;
}

export class RoleConfiguration extends React.Component<CardStatsProps, {}> {

    constructor(props: CardStatsProps) {
        super(props);
        this.goToPage = this.goToPage.bind(this);
    }

    render() {

        if (this.props.data.loading || !this.props.data) {
            return (
                <CardWrapper className="card-wrapper">
                    <RvLoader size="small" />
                </CardWrapper>
            );
        }

        const { roleCounts } = this.props.data.administrator.adminStatistics;
            
        return (
            <React.Fragment>
                <CommonMaterialCard
                    onClick={() => this.goToPage(roleCounts.total > 0, RoleCategory.all)}
                    icon={<GroupAddIcon />}
                    title="Roles"
                    description="Configure the roles for users"
                    items={[
                        {
                            name: 'All',
                            onClick: () => this.goToPage(roleCounts.total > 0, RoleCategory.all),
                            count: roleCounts.total
                        },
                        {
                            name: 'Internal',
                            onClick: () => this.goToPage(roleCounts.internal > 0, RoleCategory.internal),
                            count: roleCounts.internal
                        },
                        {
                            name: 'External',
                            onClick: () => this.goToPage(roleCounts.external > 0, RoleCategory.external),
                            count: roleCounts.external
                        },

                    ]}
                />
            </React.Fragment>
        );
    }

    private goToPage(isAllowed: boolean, params: string) {
        // tslint:disable-next-line: no-console
        console.log('test', isAllowed, params);
        if (isAllowed) {
            browserHistory.push(linkToPage('admin/roleSummary/' + params));
        }
    }
}

const roleCountstatistics = gql`{
    administrator {
      adminStatistics {
        roleCounts {
            external
            internal
            total
        }
      }
    }
  }`;

// tslint:disable-next-line:no-any
export const RoleConfigurationCard = graphql<any, CardStatsProps, any>
    (roleCountstatistics, {
        options: { variables: {} }
    })
    (RoleConfiguration);